<template>
  <PageBase
    title="采购单管理"
    :tabs="statusList"
    :tab="status"
    :pageNum="page.num"
    :total="page.total"
    :pageSize="page.size"
    @page-change="onPageChange"
    @page-size-change="onPageSizeChange"
    @tab-switch="statusChange"
  >
    <OrderTable style="height: 100%" v-loading="loading" :data-list="dataList" @dataUpdate="loadData" show-type="normal"></OrderTable>
    <div slot="search">
      <fm-form inline>
        <fm-form-item style="margin-bottom: 0;padding-top: 10px; padding-bottom: 10px;" label="订单号:">
          <fm-input-new v-model="search.id" placeholder="请输入订单号" />
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;padding-top: 10px; padding-bottom: 10px;" label="供应商:">
          <fm-select v-model="search.saleMainbody" placeholder="请选择"></fm-select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;padding-top: 10px; padding-bottom: 10px;" label="采购日期:">
          <fm-date-picker v-model="search.createTime" style="width: 200px" placeholder="日期范围选择" type="daterange"></fm-date-picker>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;padding-top: 10px; padding-bottom: 10px;" label="下单方式:">
          <fm-select v-model="search.makeType" placeholder="请选择"></fm-select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;padding-top: 10px; padding-bottom: 10px;">
          <fm-btn @click="onSearch">查询</fm-btn>
          <fm-btn @click="resetSearch">重置</fm-btn>
        </fm-form-item>
      </fm-form>
    </div>
  </PageBase>
  <!-- <SimplePageNorm title="采购单管理">
    <StatusTabs :status-list="statusList" v-model="status" @change="statusChange"></StatusTabs>
    <OrderTable style="width:100%;height:calc(100% - 38px);" v-loading="loading" :data-list="dataList" @dataUpdate="loadData" show-type="normal"></OrderTable>
  </SimplePageNorm> -->
</template>

<script>
import PageBase from '@/components/page/base'
import OrderTable from './detail/orderTable'

import {
  orderRequest
} from '@/api'

function getDefaultSearch () {
  return {
    id: null,
    saleMainbody: null,
    createTime: null,
    makeType: null
  }
}

export default {
  components: {
    PageBase,
    OrderTable
  },
  created() {
    this.loadData()
  },
  data () {
    return {
      loading: false,
      status: 'wait_check',
      page: {
        size: 20,
        num: 1,
        total: 0
      },
      search: getDefaultSearch(),
      statusList: [
        {
          value: 'wait_check',
          label: '待审核',
          parm: {
            statusList: 'edit,wait'
          }
        },
        {
          value: 'wait_pay',
          label: '待付款',
          parm: {
            billStatus: 0
          }
        },
        {
          value: 'wait_ship',
          label: '待发货',
          parm: {
            statusList: 'pass',
            haveWaitShip: 1
          }
        },
        {
          value: 'wait_receipt',
          label: '待收货',
          parm: {
            haveSending: 1
          }
        },
        {
          value: 'receipt',
          label: '已收货',
          parm: {
            haveInventory: 1
          }
        },
        {
          value: 'check_down',
          label: '审核未通过',
          parm: {
            statusList: 'down'
          }
        }
      ],
      dataList: []
    }
  },
  methods: {
    resetSearch () {
      this.search = getDefaultSearch()
    },
    async loadData () {
      this.loading = true
      let parm = {
        typeList: 'normal',
        pageSize: this.page.size,
        pageNum: this.page.num
      }

      if (this.search.createTime) {
        parm.maxCreateTime = this.$datetime.format('Y-M-D H:I:S', this.search.createTime[0])
        parm.minCreateTime = this.$datetime.format('Y-M-D H:I:S', this.search.createTime[1])
      }

      if (this.search.id) {
        parm.id = this.search.id
      }

      if (this.search.saleMainbody) {
        parm.saleMainbody = this.search.saleMainbody
      }

      if (this.search.makeType) {
        parm.makeType = this.search.makeType
      }

      let status = this.statusList.find(v => v.value == this.status)
      parm = Object.assign(parm, (status ? status.parm : {}))
      const res = await orderRequest.get(parm)
      this.dataList = res.data
      this.page.total = res.total
      let statusMap = {
        edit: '调整中',
        wait: '待审核',
        pass: '审核通过',
        down: '审核不通过'
      }
      this.dataList.forEach(v => {
        v.statusText = statusMap[v.status]
        if (v.type.includes('back')) {
          v.makeType = '退货'
        } else if (v.sourceType === 'illness_case') {
          v.makeType = '处方售药共享药房'
        } else if (v.sourceType === 'patients') {
          v.makeType = '直接售药共享药房'
        } else {
          v.makeType = v.makeMainbodyId === v.buyMainbodyId ? '自主下单' : '代客下单'
        }
      })
      this.loading = false
    },
    onSearch () {
      this.page.num = 1
      this.loadData()
    },
    statusChange (value) {
      this.page.num = 1
      this.status = value
      this.loadData()
    },
    onPageChange (page) {
      this.page.num = page
      this.loadData()
    },
    onPageSizeChange (size) {
      this.page.num = 1
      this.page.size = size
      this.loadData()
    }
  }
}
</script>

<style scoped lang="less"></style>